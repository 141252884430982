import React, { useState, useEffect } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import Alert from "@mui/material/Alert";
import { SUPPORT_EMAIL } from "../../constants";
import { useSelector } from 'react-redux';

export function CreateAccountModal({
  isOpen,
  handleClose,
  triggerParentUpdate,
  onAccountCreated,
}) {
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const employee = useSelector((state) => state.employee); 
  const [accountType, setAccountType] = useState("");
  const [accountTypes, setAccountTypes] = useState([]);

  const handleAccountTypeChange = (event) => setAccountType(event.target.value);
  const handleAccountNameChange = (event) => setAccountName(event.target.value);

  // Function to reset all form fields
  const resetFormFields = () => {
    setAccountName("");
    setAccountType("");
    setLoading(false);
    setAlertMessage("");
    setSeverity("");
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    decoratedFetch("/list_account_types")
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' should be an array of contact types, check if it's actually an array
        if (Array.isArray(data)) {
          setAccountTypes(data);
        } else {
          console.error("Expected an array of contact types, received:", data);
          setAccountTypes([]); // Set to an empty array if the response is not as expected
        }
      })
      .catch((error) => {
        console.error("Error fetching contact types:", error);
        setAccountTypes([]); // Set to an empty array in case of an error
      });
  }, [isOpen]);

  // Extended handleClose function that resets the fields
  const extendedClose = () => {
    handleClose(); // Call the original handleClose to manage the modal state
    resetFormFields(); // Reset form fields after closing
  };

  const handleSend = () => {
    setLoading(true);
    if (!accountName || !accountType) {
      setSeverity("error"); //Set alert type
      setAlertMessage(
        "Missing required fields",
      );
      setLoading(false); // Stop loading in case of error
      setTimeout(() => setAlertMessage(""), 2500);
      return;
    }

    // Prepare the payload
    const payload = {
      name: accountName,
      account_type_id: accountType,
    };
    // Make the POST request
    decoratedFetch("/create_account", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        switch (response.status) {
          case 201:
            return response.json();
          case 400:
            setAlertMessage("Missing required fields");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setLoading(false); // Stop loading
        setSeverity("success"); //set alert type
        onAccountCreated(data);
        triggerParentUpdate();
      })
      .catch((error) => {
        console.error("Error:", error); //Log the error
        setSeverity("error"); //Set alert type
        setLoading(false); // Stop loading in case of error
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(""); // Clear success message
  };

  return (
    <Modal
      disableScrollLock
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={() => {
        extendedClose();
        setAlertMessage(""); // Also clear success message when closing
      }}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <Typography variant="h6" sx={{ paddingBottom: 1 }}>
              Create New Account
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Account Name"
                  fullWidth
                  variant="outlined"
                  value={accountName}
                  onChange={handleAccountNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="account-type-label">Account Type</InputLabel>
                  <Select
                    labelId="account-type-label"
                    id="Account Type"
                    value={accountType}
                    label="Account Type"
                    onChange={handleAccountTypeChange}
                    required
                  >
                    {accountTypes.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingTop: 10,
                }}
              >
                <CircularProgress
                  size="25px"
                  sx={{
                    textAlign: "center",
                    mt: "6x",
                    mb: "5.5px",
                    mr: "20px",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingTop: 10,
                }}
              >
                <Button
                  style={{ marginRight: 10 }}
                  variant="outlined"
                  onClick={handleClose}
                  disabled={alertMessage}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSend}
                  disabled={alertMessage}
                >
                  Create
                </Button>
              </div>
            )}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
