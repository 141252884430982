import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  SUPPORTED_IMG_FILE_TYPES,
  SUPPORTED_DOC_FILE_TYPES,
} from "../constants";

const FilePreview = ({
  handleClose,
  url,
  file,
  previewPrevFile,
  previewNextFile,
  transitionComponent,
}) => {
  const renderPreview = () => {
    if (file.type === "pdf") {
      return (
        <iframe src={url} width="100%" height="100%">
          This browser does not support PDFs. Please download the PDF to view
          it: <a href={url}>Download PDF</a>.
        </iframe>
      );
    } else if (SUPPORTED_DOC_FILE_TYPES.includes(file.type)) {
      const googleViewerUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(url)}`;
      return (
        <iframe src={googleViewerUrl} width="100%" height="100%">
          If you can't view the file, download it here:{" "}
          <a href={url}>Download File</a>.
        </iframe>
      );
    } else if (SUPPORTED_IMG_FILE_TYPES.includes(file.type)) {
      return (
        <img
          src={url}
          alt="Preview"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
        />
      );
    } else {
      console.error("Unsupported file type for previews");
    }
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={transitionComponent}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          height: "90vh",
          width: "65vw",
          maxWidth: "100%",
          m: "auto",
        },
      }}
      disableScrollLock
    >
      <DialogTitle>{file.name}</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center", // Aligns the content (image) horizontally at the center
          justifyContent: "center", // Aligns the content (image) vertically at the center
          overflow: "hidden", // Prevents the image from overflowing the content area
          px: "10px",
        }}
      >
        {!previewPrevFile || !previewNextFile ? null : (
          <IconButton onClick={previewPrevFile} sx={{ mr: "10px" }}>
            <ArrowBack />
          </IconButton>
        )}
        {renderPreview()}
        {!previewPrevFile || !previewNextFile ? null : (
          <IconButton onClick={previewNextFile} sx={{ ml: "10px" }}>
            <ArrowForward />
          </IconButton>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilePreview;
