import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Paper,
  Grid,
  Autocomplete,
  FormControlLabel,
  DialogActions,
  Checkbox,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { SUPPORT_EMAIL } from "../../constants";

export function CreateAddressModal({
  isOpen,
  handleClose: handleCloseProp,
  triggerParentUpdate,
  project_address,
  setSnackbarOpen,
  setSnackbarMessage,
  account_id,
  canDelete,
}) {
  const [line_1, setAddressLn1] = useState("");
  const [line_2, setAddressLn2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [addressType, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isAddToAccountChecked, setIsAddToAccountChecked] = useState(false);
  const [hasAccountId, setHasAccountId] = useState(false);

  const handleAddressLn1Change = (event) => setAddressLn1(event.target.value);
  const handleAddressLn2Change = (event) => setAddressLn2(event.target.value);
  const handleCityChange = (event) => setCity(event.target.value);
  const handleStateChange = (event) => setState(event.target.value);
  const handleZipChange = (event) => setZip(event.target.value);
  const handleTypeChange = (event) => setType(event.target.value);
  const handleCheckboxChange = (event) => {
    setIsAddToAccountChecked(event.target.checked);
  };
  let { project_id } = useParams();
  const [addressTypes, setAddressTypes] = useState([]);
  const actionButtonText = project_address ? "Save" : "Create";

  useEffect(() => {
    if (project_address) {
      setAddressLn1(project_address?.address?.line_1 || "");
      setAddressLn2(project_address?.address?.line_2 || "");
      setCity(project_address?.address?.city || "");
      setState(project_address?.address?.state || "");
      setZip(project_address?.address?.zip || "");
      setType(project_address?.project_address_type || "");
      setIsAddToAccountChecked(Boolean(project_address?.account_id));
      setHasAccountId(Boolean(project_address?.account_id));
    } else {
      resetFields();
    }
  }, [project_address]);

  const resetFields = () => {
    setAddressLn1("");
    setAddressLn2("");
    setCity("");
    setState("");
    setZip("");
    setType("");
    setLoading(false);
    setAlertMessage("");
    setSeverity("");
    setIsAddToAccountChecked(false);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    decoratedFetch("/list_project_address_types")
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' should be an array of address types, check if it's actually an array
        if (Array.isArray(data)) {
          setAddressTypes(data);
        } else {
          console.error("Expected an array of address types, received:", data);
          setAddressTypes([]); // Set to an empty array if the response is not as expected
        }
      })
      .catch((error) => {
        console.error("Error fetching address types:", error);
        setAddressTypes([]); // Set to an empty array in case of an error
      });
  }, [isOpen]);

  const handleDelete = () => {
    setLoading(true);

    // Assuming you have a delete endpoint and address.id is available
    const url_suffix = `/delete_project_address/${project_address.id}`;
    decoratedFetch(url_suffix, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          triggerParentUpdate();
          handleClose();
          setSnackbarOpen(true);
          setSnackbarMessage(`Address deleted`);
        } else {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSeverity("error");
        setAlertMessage(
          `Failed to delete address. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
        setLoading(false);
      });
  };

  const handleSend = () => {
    setLoading(true);

    if (!addressType || !city || !state || !line_1 || !zip) {
      setSeverity("error");
      setAlertMessage(
        "Required fields not filled in. Addresses must have the following: Type, Address Line 1, City, State, Zip",
      );
      setLoading(false);
      return;
    }

    const url_suffix = project_address
      ? `/update_address/${project_address?.id}`
      : "/create_address";
    const method = project_address ? "PUT" : "POST";

    const payload = {
      address_type: addressType,
      address: {
        line_1: line_1,
        line_2: line_2,
        city: city,
        state: state,
        zip: zip,
      },
    };

    if (account_id && !project_id) {
      // This checks for any "truthy" value including non-empty strings, non-zero numbers, etc.
      payload.account_id = account_id;
    } else if (project_id) {
      // This checks for any "truthy" value including non-empty strings, non-zero numbers, etc.
      payload.project_id = project_id;
      if (isAddToAccountChecked) {
        payload.account_id = account_id;
      }
    }

    // Make the POST request
    decoratedFetch(url_suffix, {
      method: method,
      body: JSON.stringify(payload),
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          case 201:
            return response.json();
          case 400:
            setAlertMessage(
              "Required fields not filled in. Addresses must have the following: Type, Address Line 1, City, State, Zip",
            );
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please address ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please address ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setLoading(false);
        triggerParentUpdate();
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage(
          `Address ${project_address ? "updated" : "created"}`,
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setSeverity("error");
        setLoading(false);
        setAlertMessage(
          `Failed to ${project_address ? "update" : "create"} address. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resetFields();
    handleCloseProp();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
    setSeverity("");
  };

  return (
    <Modal
      disableScrollLock
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <Typography
              variant="h5"
              id="transition-modal-title"
              paddingBottom={1}
            >
              Create Address
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="Type"
                  required
                  value={addressType}
                  freeSolo
                  options={addressTypes.map((option) => option.display_name)}
                  renderInput={(params) => (
                    <TextField {...params} label="Type" />
                  )}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      setType(newInputValue);
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setType(newValue);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 1"
                  fullWidth
                  variant="outlined"
                  value={line_1}
                  onChange={handleAddressLn1Change}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="City"
                  type="city"
                  fullWidth
                  variant="outlined"
                  value={city}
                  onChange={handleCityChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 2"
                  type="address line 2"
                  fullWidth
                  variant="outlined"
                  value={line_2}
                  onChange={handleAddressLn2Change}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  label="State"
                  type="state"
                  fullWidth
                  variant="outlined"
                  value={state}
                  inputProps={{ maxLength: 2 }}
                  onChange={handleStateChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Zip Code"
                  type="zip"
                  fullWidth
                  variant="outlined"
                  value={zip}
                  inputProps={{ maxLength: 5 }}
                  onChange={handleZipChange}
                />
              </Grid>
            </Grid>
            <DialogActions
              sx={{
                justifyContent: "space-between",
                padding: 0,
                height: "40px",
              }}
            >
              {project_id && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAddToAccountChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Add to Account"
                  disabled={hasAccountId}
                  sx={{ width: "100%" }}
                />
              )}
              {loading ? (
                <CircularProgress size="35px" sx={{ mr: "30px", mt: "5px" }} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    paddingTop: 10,
                  }}
                >
                  <Button
                    style={{ marginRight: 10 }}
                    variant="outlined"
                    onClick={handleClose}
                    disabled={alertMessage}
                  >
                    Cancel
                  </Button>
                  {(!hasAccountId || canDelete) && (
                    <Button
                      style={{ marginRight: 10 }}
                      variant="contained"
                      color="secondary"
                      onClick={handleDelete}
                      disabled={alertMessage}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    disabled={alertMessage}
                  >
                    {actionButtonText}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
