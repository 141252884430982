import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import { decoratedFetch } from "../../req_utils";
import { SUPPORT_EMAIL } from "../../constants";

const SavedMaterialItemDialog = ({
  isOpen,
  materialItem,
  handleClose,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [color, setColor] = useState("");
  const [colors, setColors] = useState([]);
  const [style, setStyle] = useState("");
  const [styles, setStyles] = useState([]);
  const [size, setSize] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const resetFields = () => {
    setType("");
    setColor("");
    setStyle("");
    setSize("");
    setVendor("");
    setUnit("");
    setUnitPrice("");
    setLoading(false);
    setAlertMessage("");
  };

  const initializeFromMaterialItemProp = () => {
    setType(materialItem?.material_type || "");
    setStyle(materialItem?.material_style || "");
    setColor(materialItem?.material_color || "");
    setVendor(materialItem?.material_vendor || "");
    setSize(materialItem?.material_size || "");
    setUnit(materialItem.size_unit.id || "");
    setUnitPrice(materialItem?.unit_price || "");
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (materialItem) {
      initializeFromMaterialItemProp();
    } else {
      resetFields();
    }
    decoratedFetch("/list_material_types")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setTypes(data))
      .catch((error) =>
        console.error(`Error fetching material types ${error}`),
      );
    decoratedFetch("/list_size_units")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setUnits(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_material_vendors")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setVendors(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_material_colors")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setColors(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_material_styles")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setStyles(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
  }, [isOpen]);

  const handleDelete = () => {
    setLoading(true);

    const url_suffix = `/delete_saved_material_item/${materialItem.id}`;
    decoratedFetch(url_suffix, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          handleClose();
          setSnackbarOpen(true);
          setSnackbarMessage(`Material item deleted`);
        } else {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        //setSeverity('error');
        setAlertMessage(
          `Failed to delete material item. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
        setLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!type || !unit) {
      setAlertMessage(
        "Required fields not filled in. Items must have a type and unit",
      );
      setLoading(false);
      return;
    }
    const api = materialItem
      ? `/update_saved_material_item/${materialItem.id}`
      : "/create_saved_material_item";
    decoratedFetch(api, {
      method: materialItem ? "PUT" : "POST",
      body: JSON.stringify({
        material_type: type,
        material_size: size,
        material_color: color,
        material_vendor: vendor,
        material_style: style,
        unit: unit,
        unit_price: unitPrice ? unitPrice : parseFloat(0).toFixed(2),
      }),
    })
      .then((response) => {
        if (!materialItem && response.status === 201) {
          return;
        }
        if (materialItem && response.status === 200) {
          return;
        }
        switch (response.status) {
          case 400:
            setAlertMessage(
              "Required fields not filled in. Items must have a type and unit",
            );
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error();
      })
      .then(() => {
        setLoading(false);
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage(`Material ${materialItem ? "updated" : "created"}`);
      })
      .catch((error) => {
        console.error(
          `Error ${materialItem ? "editing" : "creating"} material: ${error}`,
        );
        setLoading(false);
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <Dialog
      disableScrollLock
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: "650px" }, // Targets the inner Paper component
      }}
    >
      <DialogTitle sx={{ fontSize: "22px", paddingBottom: "0px" }}>
        {materialItem ? "Edit Material Item" : "Create Material Item"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" sx={{ marginTop: "2px" }}>
          {alertMessage && (
            <Grid item xs={12}>
              <Alert
                onClose={handleAlertClose}
                severity="error"
                sx={{ width: "100%", borderRadius: 8 }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid item xs={6}>
            <Autocomplete
              id="Type"
              required
              value={type}
              freeSolo
              options={types.map((option) => option.display_name)}
              renderInput={(params) => <TextField {...params} label="Type" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setType(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setType(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Vendor"
              value={vendor}
              freeSolo
              options={vendors.map((option) => option.display_name)}
              renderInput={(params) => (
                <TextField {...params} label="Manufacturer" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setVendor(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setVendor(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Style"
              value={style}
              freeSolo
              options={styles.map((option) => option.display_name)}
              renderInput={(params) => (
                <TextField {...params} label="Product" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setStyle(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setStyle(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Color"
              value={color}
              freeSolo
              options={colors.map((option) => option.display_name)}
              renderInput={(params) => <TextField {...params} label="Color" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setColor(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setColor(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Size"
              fullWidth
              variant="outlined"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel>Unit</InputLabel>
              <Select
                id="unit-select"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                label="Unit"
                fullWidth
              >
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Unit Price"
              id="unit price"
              type="number"
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
              onBlur={() =>
                setUnitPrice(parseFloat(unitPrice).toFixed(2).toString())
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-end", margin: "5px", marginRight: "15px" }}
      >
        {loading ? (
          <CircularProgress
            size="25px"
            sx={{ textAlign: "center", mt: "6px", mb: "5.5px", mr: "20px" }}
          />
        ) : (
          <div>
            <Button
              sx={{ marginRight: "10px" }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {materialItem && (
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                disabled={alertMessage}
              >
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={alertMessage}
            >
              {materialItem ? "Save" : "Create"}
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SavedMaterialItemDialog;
