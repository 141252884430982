import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { SUPPORT_EMAIL } from "../constants";

const ResetPasswordPage = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const dispatchState = useDispatch();
  const theme = useTheme();
  const [alertMessage, setAlertMessage] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmedPasswordMessage, setConfirmedPasswordMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setAlertMessage("");
    setPasswordMessage("");
    setConfirmedPasswordMessage("");
    setSuccess(false);

    if (!password || !confirmedPassword) {
      if (!password) {
        setPasswordMessage("Required");
      }
      if (!confirmedPassword) {
        setConfirmedPasswordMessage("Required");
      }
      return;
    }

    if (password !== confirmedPassword) {
      setConfirmedPasswordMessage("Passwords must match");
      return;
    }

    setSubmitDisabled(true);

    decoratedFetch(
      "/change_password_with_token",
      {
        method: "POST",
        body: JSON.stringify({
          password: password,
          token: new URLSearchParams(loc.search).get("t"),
        }),
      },
      /*no_refresh=*/ true,
    )
      .then((res) => {
        switch (res.status) {
          case 200:
          case 401:
          case 403:
            return res.json();
          case 400:
            setAlertMessage("Missing password");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        setAlertSeverity("error");
        throw new Error("Error message generated");
      })
      .then((data) => {
        if (data.msg !== "success") {
          setAlertSeverity("error");
          setAlertMessage(data.msg);
          throw new Error("Error message generated");
        }
        decoratedFetch("/look_up_employee")
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 404:
                setAlertMessage(
                  `User does not exist. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
                break;
              case 401:
                setAlertMessage(
                  `User unauthenticated. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
                break;
              case 500:
                console.log(
                  `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
                break;
              default:
                setAlertMessage(
                  `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
            }
            setAlertSeverity("error");
            throw new Error("Error message generated");
          })
          .then((data) => {
            dispatchState({
              type: "mark_as_authenticated",
              employee: data,
              selectedPage: "Dashboard",
            });
            navigate("/");
          })
          .catch((error) => {
            setSubmitDisabled(false);
            setAlertSeverity("error");
            if (error.message !== "Error message generated") {
              setAlertMessage(
                `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
              );
            }
          });
      })
      .catch((error) => {
        setSubmitDisabled(false);
        setAlertSeverity("error");
        if (error.message !== "Error message generated") {
          setAlertMessage(
            `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
          );
        }
      });
  };

  return (
    <Box
      sx={{
        display: "grid",
        marginTop: "30px",
      }}
    >
      {alertMessage ? (
        <Box
          sx={{
            width: "60%",
            mx: "auto",
          }}
        >
          <Collapse in={alertMessage.length}>
            <Alert
              variant="outlined"
              severity={alertSeverity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertMessage("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <span dangerouslySetInnerHTML={{ __html: alertMessage }} />
            </Alert>
          </Collapse>
        </Box>
      ) : null}
      <Box
        sx={{
          mx: "auto",
          marginTop: "60px",
          "& img": {
            width: {
              xs: "180px",
              sm: "295px",
            },
          },
        }}
      >
        <img src="/genesis_logo.png" alt="Genesis Floor Covering Inc. logo" />
      </Box>
      <Box
        sx={{
          mx: "auto",
          marginTop: {
            xs: "40px",
            sm: "60px",
          },
        }}
      >
        <Typography
          style={{ fontWeight: "bold" }}
          sx={{
            textAlign: "center",
            [theme.breakpoints.up("xs")]: {
              typography: "h6",
              component: "h6",
              size: "medium",
            },
            [theme.breakpoints.up("sm")]: {
              typography: "h4",
              component: "h4",
              size: "medium",
            },
          }}
        >
          Reset password
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mx: "auto",
          width: {
            xs: "300px",
            sm: "400px",
          },
          marginTop: {
            xs: "15px",
            sm: "30px",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="password"
              label="Password"
              type="password"
              name="password"
              autoComplete="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordMessage}
              helperText={passwordMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="confirmedPassword"
              label="Confirm Password"
              type="password"
              name="confirmedPassword"
              autoComplete="confirmed-password"
              value={confirmedPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              error={confirmedPasswordMessage}
              helperText={confirmedPasswordMessage}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={submitDisabled}
          sx={{ mt: 3, mb: 2 }}
        >
          Reset Password
        </Button>
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
